@import "../../../assets/styles/variables";

.noChatInfo {
    align-items: center;
    color: $warning-color;
    display: flex;
    flex-direction: column;
    font-size: $xxl-font-size; // 3rem;
    height: 73.1rem;
    justify-content: center;

    p {
        font-size: $base-m-font-size;
        text-align: center;
    }
}

.chatWrapper {
    box-sizing: border-box;
    display: flex;
    height: 100%;
}

.chatWindowWrapper {
    flex: 3
}

.activeError {
    color: $danger-color;
    font-weight: 600;
    margin-top: 0.5rem;
}

.inactiveError {
    color: $dark-color;
    margin-top: 0.5rem;
    opacity: 0.4;
}
