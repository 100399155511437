@import url("~leaflet/dist/leaflet.css");
@import url("~leaflet-draw/dist/leaflet.draw.css");
@import "~marine-panel-styles/normalize";
@import "~marine-panel-styles/panel/variables";
@import "~marine-panel-styles/panel/style";
@import "./leaflet.scss";
@import "./main-map-sidebar.scss";
@import "./edit-wizard-host.scss";
@import "./create-modal.scss";
@import "./init-berth-wizard.scss";
@import "./marina-berth.scss";


.sr-only {
    border: 0 !important;
    clip: rect(0.1rem, 0.1rem, 0.1rem, 0.1rem) !important;
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;
    height: 0.1rem !important;
    margin: -0.1rem !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    white-space: nowrap !important;
    width: 0.1rem !important;
}

.horizontal-spacer {
    margin: 0 1rem;
}

p {
    margin-bottom: 0;
}

.lazy-image-wrapper {
    align-items: center;
    aspect-ratio: 16/9;
    border-radius: 0.4rem;
    display: flex;
    height: 4rem;

    &.loading {
        background-color: $base-img-background;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        border-radius: 0.5rem;
        position: relative;

        &::after{
            background-image: url(../images/anchorTiny.svg);
            background-position: center;
            background-repeat: no-repeat;
            content: '';
            height: 1.6rem;
            left:2.4rem;
            position: absolute;
            top: 1rem;
            width: 1.6rem;
        }
    }
}

.sm-btn {
    align-items: center;
    border: thin solid transparent;
    display: flex;
    font-family: Montserrat, sans-serif;
    font-size: $base-font-size;
    font-style: normal;
    font-weight: 500;
    justify-content: center;
    letter-spacing: 0.036rem;
    line-height: 1.5rem;
    padding: 0.6rem 0.8rem;

    &.borderless {
        border: none;
    }

    &.smaller {
        font-size: $small-font-size;
    }

    &:disabled {
        opacity: 0.4;
    }

    &.wide {
        width: 100%;
    }

    &.btn-create {
        border: 1px solid $black-color;

        &:hover {
            border-color: $primary-color;
        }
    }
}

.xs-btn {
    align-items: center;
    border: thin solid transparent;
    display: flex;
    font-family: Montserrat, sans-serif;
    font-size: $small-font-size;
    font-style: normal;
    font-weight: 500;
    justify-content: center;
    letter-spacing: 0.036rem;
    line-height: 1.5rem;
    padding: 0.3rem 0.4rem;

    &.borderless {
        border: none;
    }
}

.btn-black-white {
    background-color: transparent;
    border: thin solid $lighter-text-color;
    border-radius: 0.5rem;
    color: $basic-text-color;

    &:hover {
        border-color: $basic-text-color;
        text-decoration: underline;
    }
}

.btn-red {
    background-color: $white-color;
    border: thin solid transparent;
    border-radius: 0.5rem;
    color: $error-color;

    &:hover {
        border-color: $disabled-day-color;
        color: $disabled-day-color;
    }
}

.information-text {
    font-size: $base-font-size;
    font-weight: 500;
}
