@import "../../../assets/styles/variables";

.stripeDetails {
    display: flex;
    flex-wrap: wrap;

    .detailsContainer {
        display: flex;
        flex: 1;
        flex-direction: column;

        .header {
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            font-size: $base-ms-font-size;
            font-weight: 500;
            padding: 1.5rem;

            svg {
                color: $primary;
                margin-right: 0.5rem;
            }
        }

        .description {
            font-size: $base-font-size;
            padding: 1.5rem;

            p {
                margin-bottom: 0.5rem;
            }

            .warning {
                color: $danger-color;
                margin-bottom: 0.5rem;
            }

            .onboardingCompleted {
                font-weight: 500;
            }

            button {
                font-size: $base-font-size;
                margin-left: 0;
                margin-top: 0.5rem;
            }
        }

        &:first-of-type {
            border-right: 1px solid $snippet-bg-color;
        }

        &:last-of-type {
            border-left: 1px solid $snippet-bg-color;
        }
    }
}

@media (max-width: $breakpoint-small) {
    .stripeDetails {
        flex-direction: column;

        .detailsContainer {
            &:first-of-type {
                border-right: none;
                margin-bottom: 2rem;
            }
        }
    }
}
