.marina-details-card {
    flex:1 1 auto;
    min-height: 39rem;

    .marina-details-header {
        display: flex;
        flex-wrap: wrap;
        font-weight: 700;
        justify-content: space-between;
        padding: 2.7rem;
        position: relative;

        .marina-details-title {
            font-size: $m-font-size;
            font-weight: 700;
            line-height: 2.4rem;
            padding-bottom: 1rem;
            padding-top: 1rem;
            text-transform: uppercase;
        }

        .title-icon {
            color: $primary-color;
            margin-right: 1rem;
        }

        .create-marina-button {
            border-color: $lighter-text-color;
            color: $basic-text-color;
            height: 3rem;
        }
    }

    .marina-details-table {
        .marina-item-wrapper {
            border-color: transparent;
            display: flex;
            justify-content: stretch;
            padding: 1rem 2.7rem 0 1rem;

            .marina-description-wrapper {
                display: flex;
                flex: 1;

                .marina-image {
                    background-color: $base-img-background;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    border-radius: 0.5rem;
                    height: 8.6rem;
                    margin-right: 3rem;
                    padding: 3rem;
                    position: relative;
                    width: 12rem;

                    &.placeholder {
                        position: relative;

                        &::after{
                            background-image: url(../images/anchor.svg);
                            background-position: center;
                            background-repeat: no-repeat;
                            content: '';
                            height: 3rem;
                            left:4.4rem;
                            position: absolute;
                            width: 3rem;
                        }
                    }
                }

                .marina-description {
                    font-size: $small-font-size;
                    line-height: 1.8rem;
                    max-width: 51rem;

                    .title-container {
                        display: flex;
                        padding-bottom: 1rem;
                        padding-top: 1rem;

                        .title {
                            font-weight: 600;
                        }

                        .status {
                            background-color: rgba($green, 0.12);
                            color: rgba($green, 1);
                            font-size: $xxs-font-size;
                            font-weight: 600;
                            letter-spacing: 0.2rem;
                            margin-left: 1rem;
                            padding: 0.5rem;
                            text-align: center;
                            text-transform: uppercase;
                            width: 8rem;
                        }
                    }

                    .description {
                        font-size: $small-font-size;
                        line-height: 1.8rem;
                        max-width: 51rem;
                    }
                }
            }

            .marina-details {
                align-items: flex-end;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding-right: 0;

                .edit-marina-link {
                    color: rgba($black-color, 0.8);
                    font-size: 1.4rem;
                    font-weight: 600;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

.berth-details-card {
    flex: 1 1 auto;
    min-height: 39rem;

    .berth-details-header {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 2.7rem;

        .berth-list-title {
            align-items: center;
            color: black;
            display: flex;
            flex-wrap: wrap;
            font-weight: 700;
            justify-content: space-between;
            position: relative;
            text-transform: uppercase;
        }

        .actions-wrapper {
            align-items: center;
            display: flex;
            gap: 1.7rem;

            .berth-search-value {
                .form-control-wrapper {
                    margin-bottom: 0;

                    .input-container {
                        position: relative;

                        &::before {
                            align-items: center;
                            content: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACvSURBVHgBjZGxDcIwFEQvpKHMCGECPEJGyAZ4BEbIBpSICjpaOkqUkgoxAWwAJVThTA5hOXGUk57s/607W9/AXzlZkYvYEoNAqdaSHLXfkDOZkbV6NYLkB6nQldFZ6Tfd1QfEVZGT33DFcsCQk4Zkrpio8cRIOUONnml4KsjdDy10pYk85wZvIKncCdoRvsmLTBW0l2lHrmGaRfthjfiN2qpeIKJMhGFfU4LxsmT+ATcTJKP3SmgdAAAAAElFTkSuQmCC');
                            display: flex;
                            height: 100%;
                            position: absolute;
                            right: 0;
                            width: 2rem;
                        }

                        .search-value-input {
                            border: thin solid $lighter-text-color;
                            color: $lighter-text-color;
                            height: 3rem;
                        }
                    }

                    .label-container {
                        display: none;
                    }
                }
            }
        }

    }

    .berths-table {
        .no-data-warning {
            font-size: $l-font-size;
            text-align: center;
        }

        .table-item {
            td{
                padding-bottom: 0;
                padding-top: 0;

                .status{
                    font-size: 0.9rem;
                    letter-spacing: 0.2rem;
                    line-height: 1.8rem;
                    padding: 0.1rem 0.9rem ;
                    text-transform: uppercase;
                }

                .enabled{
                    background:$status-badge-app_booking;
                    color: $purple;
                }

                .link{
                    align-items: center;
                    color:  #6e6b7b;
                    display: flex;
                    padding: 1rem 0;

                    .thumbnail-base {
                        background-color: $base-img-background;
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                        border-radius: 0.5rem;
                        height: 4rem;
                        position: relative;
                        width: 6.3rem;

                        &::after{
                            background-image: url(../images/anchorTiny.svg);
                            background-position: center;
                            background-repeat: no-repeat;
                            content: '';
                            height: 1.6rem;
                            left:2.4rem;
                            position: absolute;
                            top: 1rem;
                            width: 1.6rem;
                        }
                    }
                }
            }
        }

        .price-section {
            padding-bottom: 1rem;

            p{
                font-size: 1.4rem;
                font-weight: 400;

                span{
                    margin: 0.5rem 0.5rem 0 0;
                    margin-left: 1rem;
                }

                .strategy{
                    font-size: 1rem;
                }
            }

        }
    }
}

.berth-list-element {
    border-top: 1px solid $border-top;
    padding: 1rem;

    &:nth-child(2n){
        background: $body-bg;
    }

    .berth-list-row {
        align-items: center;
        display: flex;

        &.berth-list-row:nth-child(2) {
            justify-content: space-between;
            margin-top: 1rem;
        }

        .berth-name {
            font-size: 1.6rem;
            font-weight: 500;
        }

        .badges-section {
            display: flex;
            justify-content: space-between;
            width: 81%;

            .badges{
                display: flex;
            }

        }

        .price{
            font-size: 1.5rem;
        }

        .button-wrapper {
            display: flex;
            justify-content: space-between;

            .price-change-button {
                border-radius: 0.4rem;
                height: 2.3rem;
                margin-left: 0.5rem;
                padding: 0.2rem 0.7rem 1.5rem 1rem;
                width: 3.2rem;
            }
        }
    }
}
