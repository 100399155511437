.wizard-panel {
    align-items: stretch;
    display: flex;
    flex: 1;
    height: 100vh;
    justify-content: stretch;
    width: 100vw;

    .image-host-section {
        align-items: center;
        display: flex;
        flex: 1;
        justify-content: center;

        .image-host {
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            height: 100%;
            width: 100%;
        }
    }

    .step-host-section {
        align-items: stretch;
        background-color: $kbd-bg;
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: stretch;
        max-height: 100vh;
        overflow-y: auto;

        .host-section-header {
            align-items: center;
            display: flex;
            justify-content: center;
            padding: 1.5rem 4.5rem 2rem;
            position: relative;

            .logo-wrapper {
                align-items: center;
                display: flex;
                flex: 1;
                height: 12rem;
                justify-content: center;
            }

            .host-section-header-button {
                appearance: none;
                background: none;
                border: none;
                color: $primary-color;
                font-size: $small-font-size;
                font-weight: 400;
                outline: none;
                padding: 0;
                position: absolute;
                right: 4.5rem;
                top: 5rem;

                &.logout {
                    top: 7rem;
                }
            }
        }

        .step-body {
            align-items: stretch;
            display: flex;
            flex: 1 1;
            flex-direction: column;
            justify-content: flex-start;
            padding: 0 10rem;

            .step-body-title {
                margin-bottom: 2rem;

                .title {
                    color: $primary-color;
                    font-size: $base-m-font-size;
                    font-weight: 600;
                    margin-bottom: 1rem;

                    &.main {
                        margin-bottom: 4rem;
                        text-align: center;
                        text-transform: uppercase;
                    }
                }

                .description {
                    border-bottom: thin solid $sidebar-menu-hover-color;
                    font-size: $base-font-size;
                    font-weight: 400;
                    padding-bottom: 2rem;
                }
            }

            .step-component {
                display: flex;
                flex: unset;
                justify-content: stretch;

                &.well-done {
                    .wizard-init-form-wrapper {
                        justify-content: space-between;
                        min-height: 40rem;

                        .form-title {
                            color: $primary-color;
                            font-size: $base-m-font-size;
                            font-weight: 600;
                            text-align: center;
                        }

                        .wizard-navigation-buttons {
                            justify-content: center;
                        }
                    }
                }

                .wizard-init-form-wrapper {
                    align-items: stretch;
                    background-color: $white-color;
                    border-radius: 0.6rem;
                    display: flex;
                    flex: 1;
                    flex-direction: column;
                    justify-content: stretch;
                    padding: 3rem 5rem;
                    width: 100%;

                    .form-title {
                        color: $basic-text-color;
                        font-size: $x-font-size;
                        font-weight: 600;
                        text-align: center;
                    }


                    .basic-multi-select {
                        .select__menu {
                            z-index: 1001;
                        }

                        .select__control {
                            border-radius: 0.6rem;
                            min-height: unset;

                            .select__value-container {
                                padding: 0.4rem 1.8rem;
                            }
                        }
                    }

                    .tags-input {
                        .basic-multi-select {
                            .select__control {
                                height: unset;
                                min-height: 4.2rem;
                            }
                        }
                    }

                    .init-pricing-strategy-calendar {
                        &.form-control {
                            max-width: unset;
                        }
                    }

                    .price-summary-wrapper {
                        .form-control {
                            &.currency-input {
                                .currency-input-wrapper {
                                    .currency-input {
                                        -moz-appearance: textfield;
                                        padding-left: 1.8rem;

                                        &::-webkit-outer-spin-button,
                                        &::-webkit-inner-spin-button {
                                            -webkit-appearance: none;
                                            margin: 0;
                                        }
                                    }

                                    .input-number-indicator {
                                        display: none;
                                    }

                                    .currency-marker {
                                        background-color: transparent;
                                        border: none;
                                        left: unset;
                                        opacity: 0.5;
                                        right: 0.1rem;
                                    }
                                }
                            }
                        }

                    }

                    .wizard-form-control-wrapper {
                        border-bottom: thin solid $sidebar-menu-hover-color;
                        display: flex;
                        flex: 1;
                        justify-content: stretch;
                        padding: 4rem 0;
                        width: 100%;

                        .wizard-form-control-body {
                            border: thin solid $lightest-text-color;
                            border-radius: 0.5rem;
                            display: flex;
                            flex: 1;
                            flex-direction: column;
                            padding: 2rem 1.5rem;
                            width: 100%;
                        }
                    }

                    .wizard-navigation-buttons {
                        align-items: center;
                        display: flex;
                        justify-content: space-between;
                        margin-top: 4rem;
                        width: 100%;
                    }
                }
            }
        }
    }
}
