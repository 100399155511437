@import "../../../assets/styles/variables";

.btnWrapper {
    display: flex;
    justify-content: flex-end;
    padding: 0 1.5rem;

    button:first-of-type {
        margin-right: 1rem;
    }
}
