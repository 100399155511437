@import "../../../../../assets/styles/variables";


.chat-left {
    .avatar {
        float: left;
    }

    .chatContent {
        float: left;
        margin: 0 0 1rem 1rem;
    }
}

.avatar {
    float: right;
}

.chatBody {
    display: block;
    margin: 1rem 3rem 0;
    overflow: hidden;

    .chatContent {
        background: $white-color;
        border-radius: 0.357rem;
        box-shadow: 0 0.4rem 0.8rem 0 rgba(34, 41, 47, 0.12);
        clear: both;
        float: right;
        margin: 0 1rem 1rem 0;
        max-width: 75%;
        padding: 0.7rem 1rem;
    }
}
