.main-map-sidebar {
    background-color: $white-color;
    border-radius: 0;
    box-shadow: -0.2rem 0 2rem rgba($black-color, 0.1);
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100vh;
    margin: 0;
    overflow: auto;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 36rem;
    z-index: 1000;

    .details-item-title {
        color: $sidebar-title-color;
        flex: 1;
        font-size: $medium-font-size;
        font-weight: 600;
        line-height: 2.1rem;
        text-transform: uppercase;
    }

    .reservation-controller-buttons {
        display: flex;
        justify-content: flex-end;
        margin: 0 0.25rem;

        &.admin-controls {
            justify-content: space-between;
        }
    }

    .details-header {
        align-items: center;
        background-color: $base-background;
        display: flex;
        justify-content: space-between;
        padding: 1.2rem 2rem;

        &.reservation-view {
            .details-item-title {
                flex: 2;
            }

            .reservation-controller-buttons {
                flex: 1;
                margin-left: 1rem;
            }
        }

        .marina-controller-wrapper {
            align-items: center;
            display: flex;
            flex: 1;
            justify-content: flex-end;

            .form-wrapper-marina-select-form,
            .create-button-wrapper {
                display: flex;
                flex: 1;

                .form-control {
                    margin: 0;
                    padding: 0;

                    &.marina-selector-input {
                        .basic-single {
                            height: 100%;

                            .select_control {
                                .select__value-container {
                                    .select__single-value {
                                        color: $video-overlay-bg-color;
                                        font-size: $small-font-size;
                                        font-weight: 600;
                                    }
                                }
                            }
                        }
                    }

                    &.label-hidden {
                        .label-container {
                            display: none;
                        }
                    }

                    .basic-single {
                        margin-bottom: 0;
                        padding: 0;

                        .select__control {
                            border: unset;
                            font-size: $medium-font-size;
                            height: 3.4rem;
                            min-height: unset;

                            &--is-focused {
                                border: unset;
                            }

                            .select__indicator-separator {
                                display: none;
                            }
                        }
                    }
                }
            }

            .create-button-wrapper {
                justify-content: flex-end;
            }

            .marina-select-form {
                .form-control-wrapper {
                    &.marina-selector-input {
                        .basic-single .select__control .select__value-container .select__single-value  {
                            color: $sidebar-title-color;
                            font-size: $medium-font-size;
                            font-weight: 500;
                        }

                        .basic-single .select__control .select__dropdown-indicator {
                            svg {
                                display: flex;
                                fill: $primary-color;
                                flex: 1;
                                height: 1.5rem;
                                width: 1.5rem;
                            }

                            &::after {
                                display: none;
                            }
                        }
                    }
                }
            }
        }



        .details-button-wrapper {
            align-items: center;
            display: flex;
            justify-content: flex-end;
        }

        .close-button {
            appearance: none;
            background-color: transparent;
            border-radius: 50%;
            color: $section-title-color;
            cursor: pointer;
            font-size: $small-font-size;
            font-weight: 600;
            height: 2rem;
            position: absolute;
            right: 1rem;
            top: 1rem;
            width: 2rem;
        }
    }

    .details-body {
        display: flex;
        flex: 1;
        flex-direction: column;
        padding: 1rem 2rem;

        .empty-list-info {
            display: flex;
            flex: 1;
            font-size: $base-m-font-size;
            font-weight: 500;
            justify-content: center;
            text-align: center;
        }

        .marina-reservation-list {
            border-top: solid 1px $border-color;
            list-style: none;
            margin: -2rem 0;
            padding: 1rem 0;

            .marina-reservation-item {
                border-bottom: solid 1px $border-color;
                display: flex;
                flex-direction: column;
                padding: 1rem;

                &:hover,
                &:focus {
                    .item-details-wrapper {
                        .reservation-view-button {
                            visibility: visible;
                        }
                    }
                }

                .item-status {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 0.5rem;

                    .item-title {
                        color: $black-color;
                        font-size: $medium-font-size;
                        font-weight: 500;
                        line-height: 2.3rem;
                        text-transform: uppercase;
                    }

                    .reservation-status-wrapper {
                        display: flex;
                        gap: 1rem;

                        .status-badge-wrapper {
                            display: flex;
                            justify-content: flex-end;

                            .badge-item {
                                padding: 0.8rem;

                                .badge-icon {
                                    height: 1.6rem;
                                    stroke-width: 0.2rem;
                                    width: 1.6rem;
                                }

                                &.active {
                                    background: $status-badge-active;
                                    color: $primary;
                                }

                                &.draft {
                                    background: $status-badge-draft;
                                    color: $dark;
                                }

                                &.inactive {
                                    background: $status-badge-inactive;
                                    color: $red;
                                }

                                &.finished {
                                    background: $status-badge-finished;
                                    color: $green;
                                }

                                &.cancelled {
                                    background: $status-badge-cancelled;
                                    color: $red;
                                }

                                &.app_booking {
                                    background: $status-badge-app_booking;
                                    color: $purple;
                                }

                                &.available {
                                    background: $status-badge-available;
                                    color: $success;
                                }

                                &.reserved {
                                    background: $status-badge-reserved;
                                    color: $dark;
                                }

                                &.paid {
                                    background: $status-badge-paid;
                                    color: $orange;
                                }

                                &.yacht_arrived {
                                    background: $status-badge-yacht_arrived;
                                    color: $blue;
                                }

                                &.is_manual {
                                    background: $status-badge-is_manual;
                                    color: $blue;
                                }
                            }
                        }
                    }
                }

                .item-details-wrapper {
                    display: flex;
                    justify-content: space-between;

                    .item-details {
                        align-items: center;
                        display: flex;
                        flex: 1;
                        font-size: $small-font-size;

                        .spacer {
                            margin: 0 0.5rem;
                        }
                    }

                    .reservation-view-button {
                        align-items: center;
                        background-color: $white-color;
                        border: 0.1rem solid #aaa;
                        border-radius: 0.9rem;
                        color: #aaa;
                        cursor: pointer;
                        display: flex;
                        font-weight: 600;
                        justify-content: center;
                        padding: 0.5rem 1rem;
                        text-transform: uppercase;
                        visibility: hidden;

                        &:hover {
                            background-color: $primary-color;
                            border: 0.1rem solid $primary-color;
                            color: $white-color;
                        }
                    }
                }
            }
        }

        .reservation-title {
            align-items: center;
            color: $sidebar-title-color;
            display: flex;
            font-size: $base-font-size;
            font-weight: 600;
            justify-content: space-between;
            line-height: 2.3rem;
        }

        .reservation-details {
            display: flex;
            gap: 0.5rem;
            justify-content: flex-end;

            .status-badge-wrapper {
                display: flex;
                justify-content: flex-end;

                .badge-item {
                    align-items: center;
                    display: flex;
                    height: 2.4rem;
                    justify-content: center;
                    width: 2.4rem;
                }
            }
        }

        .reservation-details-wrapper {
            color: $sidebar-content-color;
            font-size: $base-font-size;
            margin-bottom: 1rem;

            .reservation-details-header {
                align-items: center;
                display: flex;
                justify-content: space-between;
            }

            .information-details {
                .information-details-row {
                    align-items: center;
                    display: flex;
                    margin-bottom: 0.5rem;

                    .information-details-label {
                        font-weight: 600;
                        margin-right: 0.5rem;

                        .details-icon {
                            color: $primary-color;
                        }
                    }

                    .information-details-value {
                        font-weight: 400;
                        margin: 0;

                        &.price {
                            margin-right: 1rem;
                        }
                    }
                }
            }
        }

        .reservation-datepicker-wrapper {
            margin: 1rem 0;

            .reservation-datepicker {
                .react-datepicker__day-names .react-datepicker__day-name {
                    font-family: Montserrat, sans-serif;
                    font-style: normal;
                    font-weight: 400;
                }

                .month-calendar {
                    &.event-calendar {
                        &.reservation-calendar {
                            .react-datepicker {
                                color: $body-color;
                                font-family: Montserrat, sans-serif;
                                font-style: normal;

                                .react-datepicker__current-month {
                                    color: $section-title-color;
                                    line-height: 2.3rem;
                                    margin-bottom: 0;
                                }

                                .react-datepicker__day {
                                    color: $body-color;
                                    display: flex;

                                    &--selected {
                                        color: $white-color;
                                    }
                                }

                                .react-datepicker__day--outside-month {
                                    color: $text-muted;
                                }
                            }
                        }
                    }
                }
            }
        }

        .reservation-form-wrapper {
            padding: 1rem 0;

            .reservation-update-button {
                .form-control-wrapper {
                    align-items: flex-end;
                }
            }

            .textarea-container {
                width: 100%;
            }

            .switch-label-container {
                align-items: center;
                flex-direction: row;
                justify-content: flex-end;

                .label-container {
                    margin-right: 1rem;
                    position: unset;

                    .form-label {
                        color: rgba($black-color, 0.4);
                        font-size: $base-font-size;
                        font-weight: 400;
                        margin-bottom: 0;
                    }
                }
            }

            .react-datepicker__input-container {
                .form-control {
                    &.date {
                        ::after {
                            display: none;
                        }
                    }
                }
            }

            .input-number-indicator {
                display: none;
            }

            .label-container {
                .form-label {
                    color: $black-color;
                    font-size: $base-font-size;
                    font-weight: 500;
                }
            }

            .basic-single {
                .select__control {
                    .select__dropdown-indicator {
                        width: 4rem;
                    }

                    .select__indicators {
                        .select__indicator-separator {
                            display: none;
                        }

                        .select__indicator {
                            color: $text-muted;
                        }
                    }
                }
            }
        }

        .details-section-title {
            font-size: $xxs-font-size;
            font-weight: 600;
            margin-bottom: 0.5rem;
        }

        .details-section-description {
            font-size: $base-font-size;
        }

        .details-list {
            list-style: none;
            margin: 0;
            padding: 0;
        }

        .details-tags {
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            margin-bottom: 1rem;

            .details-tag {
                background-color: $primary-color;
                border-radius: 0.5rem;
                color: $white-color;
                font-size: $small-font-size;
                font-weight: 600;
                margin-right: 0.5rem;
                padding: 0.5rem 1rem;
            }
        }
    }

    .details-footer {
        align-items: center;
        display: flex;
        justify-content: center;
    }

    .delete-reservation-modal-wrapper {
        align-items: center;
        background-color: $modal-background;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 2rem;
        position: absolute;
        top: 0;
        width: 36rem;

        .delete-reservation-modal {
            background-color: $white-color;
            border-radius: 0.6rem;
            font-family: Montserrat, sans-serif;
            font-style: normal;
            padding: 2rem;

            .modal-header {
                border-bottom: thin solid $base-img-background;

                .modal-title {
                    color: $section-title-color;
                    font-size: $medium-font-size;
                    font-weight: 500;
                }

                .btn-close {
                    background-size: 1.5rem;
                    height: 2.4rem;
                    opacity: 1;
                    width: 2.4rem;
                }
            }

            .modal-body {
                color: $black-color;
                font-size: $base-font-size;
                font-weight: 400;
                padding: 2rem 0;

                .warning-wrapper {
                    background-color: $delete-warning-color;
                    border: thin solid $error-color;
                    border-radius: 0.6rem;
                    color: $error-color;
                    margin-bottom: 2rem;
                    padding: 1rem;

                    :first-child {
                        margin-right: 2rem;
                    }
                }

                .confirmation-info {
                    text-align: center;
                }
            }

            .modal-footer {
                border-top: thin solid $base-img-background;
                padding-top: 2rem;

            }
        }
    }
}
