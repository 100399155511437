@import "../../../../../assets/styles/variables";

.chatContainer {
    background: $base-background;
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: auto;

    .emptyMessagesContainer {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;

        span {
            color: $theme-second-color;
            font-size: $xxxxml-font-size;
            margin-bottom: 1.6rem;
        }

        p {
            color: $theme-second-color;
            font-family: Ubuntu, sans-serif;
            font-size: $base-font-size;
            line-height: 1.7rem;
        }
    }
}

@media(max-width: $breakpoint-large) {
    .chatContainer {
        height: 30rem;

        .emptyMessagesContainer {
            height: 30rem;
        }
    }
}
