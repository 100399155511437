@import "../../../../../assets/styles/variables";

.interactionContainer {
    align-items: center;
    background-color: $chat-bg-color;
    border-top: 0.1rem solid $chat-border-color;
    box-sizing: border-box;
    display: flex;
    padding: 1.6rem;
    position: relative;
    width: 100%;
    z-index: 999;

    .toggleEmoji {
        background-color: transparent;
        background-size: 1.9rem;
        border: none;
        cursor: pointer;
        height: 4rem;
        outline: none;
        position: absolute;
        width: 4rem;
        z-index: 99;

        &:disabled {
            cursor: not-allowed;
        }
    }

    .emojiPicker {
        bottom: 7.2rem;
        left: 0;
        position: absolute;
        width: 100%;
    }

    .editableDiv {
        background-color: $white-color;
        border: 0.1rem solid $chat-input-border-color;
        flex: 1;
        font-family: Poppins, sans-serif;
        font-size: $base-font-size;
        height: 4rem;
        line-height: 2rem;
        outline: none;
        overflow: auto;
        padding: 1rem 1.6rem 1.25rem 4.5rem;

        &[contentEditable="true"]:empty:not(:focus)::before {
            color: $grey-text-color;
            content: attr(data-text);
            font-family: Ubuntu, sans-serif;
            font-size: $base-font-size;
            font-weight: 400;
            letter-spacing: 0;
            line-height: 2rem;
        }

        &::-webkit-scrollbar {
            height: 0;
            width: 2rem;
        }

        &::-webkit-scrollbar-thumb {
            background-clip: padding-box;
            background-color: rgba(0, 0, 0, 0.15);
            border: 0.6rem solid rgba(0, 0, 0, 0);
            -webkit-border-radius: 1rem;
            -webkit-box-shadow: inset 0 0 0 0.1rem rgba(0, 0, 0, 0.025);
            height: 1rem;
        }

        &::-webkit-scrollbar-button {
            display: none;
            height: 0;
            width: 0;
        }

        &::-webkit-scrollbar-corner {
            background-color: transparent;
        }
    }

    .actionBtnContainer {
        align-items: center;
        display: flex;
        height: 4.6rem;
        justify-content: flex-start;

        .btnSend {
            background-size: $l-font-size;
            cursor: pointer;
            margin-left: 1rem;

            &:disabled {
                cursor: not-allowed;
            }
        }
    }
}

.errorMessage {
    background: $danger-color;
    color: $white-color;
    font-size: $m-font-size;
    height: 2rem;
    left: 0;
    position: absolute;
    text-align: center;
    top: 9rem;
    width: 100%;
}

.errorConnectionMessage {
    background: $danger-color;
    bottom: 8rem;
    color: $white-color;
    font-size: $m-font-size;
    height: 2rem;
    left: 0;
    position: absolute;
    text-align: center;
    width: 100%;
}

@media (max-width: 992px) {
    .interactionContainer {
        flex: none;

        .emojiPicker {
            bottom: 8.8rem;
        }
    }
}
