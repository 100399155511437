@import "../../../../assets/styles/variables";

.itemContainer {
    border: 1px solid rgba($black-color,0.1);
    padding: 2rem;

    .itemTitle {
        display: flex;

        .itemDate {
            font-size: $base-font-size;
            font-weight: 600;
            margin-right: 1.7rem;
        }

        .itemStatus {
            color: $primary-color;
            font-size: $base-font-size;
            font-weight: 600;
            text-transform: capitalize;

            &.draft {
                color: $info;
            }

            &.cancelled {
                color: $warning;
            }

            &.finished {
                color: $success;
            }
        }
    }

    .itemData {
        padding-left: 0;

        li {
            display: flex;
            justify-content: space-between;
            list-style: none;
            padding-top: 1.8rem;

            p {
                font-size: $base-font-size;

                &.berthName {
                    text-transform: uppercase;
                }
            }
        }
    }
}
