@import "../../../../../../assets/styles/variables";


.chatMessage {
    color: $white-color;
    font-family: Ubuntu, sans-serif;
    font-size: $base-font-size;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 2rem;
    max-width: 100%;
    overflow: hidden;
    overflow-wrap: break-word;
    white-space: pre-wrap;
    word-break: break-word;

    &.message {
        color: $white-color;
    }
}

.loaderWrapper {
    height: 2rem;
    position: relative;
    width: 2rem;
}

.responseMessage {
    opacity: 0.75;
}

.chatImage {
    border-radius: 1.72rem;
    height: 12.9rem;
    width: 17.2rem;
}
