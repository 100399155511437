@import "../../../../assets/styles/variables";


.listWrapper {
    flex: 1;

    .searchWrapper {
        border-bottom: 1px solid $black-color;
        display: flex;
        height: 6.5rem;
        padding: 1rem;
        width: 100%;

        .searchContent {
            align-items: center;
            display: flex;
            width: 100%;
        }
    }
}
