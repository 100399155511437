.pricing-strategy-calendar {
    width: 100%;

    .react-datepicker__current-month {
        font-family: Montserrat, sans-serif;
        font-size: 1.6rem;
        font-weight: 500;
    }

    .react-datepicker__month {
        border-collapse: collapse;
        margin: 0;

        .react-datepicker__day {
            border: thin solid $lightest-text-color;
            display: flex;
            flex: 1;
            font-family: Montserrat, sans-serif;
            font-style: normal;
            margin: 0;
            min-height: 5rem;

            &--outside-month {
                opacity: 0.5;

                .day-wrapper {
                    color: $lighter-text-color;
                }
            }

            &--in-range {
                background-color: $day-in-selection-color
            }

            &.react-datepicker__day--range-end, &.react-datepicker__day--range-start, &.react-datepicker__day--selected, &.react-datepicker__day--keyboard-selected {
                background-color: $primary-color;

                .day-wrapper {
                    color: $white-color;

                    .day-item, .price-item {
                        color: $white-color;
                    }
                }
            }

            .day-wrapper {
                display: flex;
                flex: 1;
                font-weight: 600;
                height: 100%;
                position: relative;

                .day-item {
                    color: $lighter-text-color;
                    font-size: $base-font-size;
                    left: 0.5rem;
                    letter-spacing: 0.1rem;
                    position: absolute;
                    top: 0.5rem;
                }

                .price-item {
                    bottom: 1rem;
                    color: $primary-color;
                    font-size: $xxxm-font-size;
                    position: absolute;
                    right: 2rem;

                    &.disactive {
                        color: $disabled-day-color;
                        text-decoration: line-through;
                    }
                }
            }
        }
    }
}


.modal-open {
    .modal {
        .modal-dialog {
            .modal-content, .wizard-init-form-wrapper {
                .modal-wrapper {
                    &.create-pricing-strategy {
                        padding: 4rem;

                        .form-control {
                            &.currency-input {
                                .currency-input-wrapper {
                                    .currency-input {
                                        -moz-appearance: textfield;
                                        padding-left: 1.8rem;

                                        &::-webkit-outer-spin-button,
                                        &::-webkit-inner-spin-button {
                                            -webkit-appearance: none;
                                            margin: 0;
                                        }
                                    }

                                    .input-number-indicator {
                                        display: none;
                                    }

                                    .currency-marker {
                                        background-color: transparent;
                                        border: none;
                                        left: unset;
                                        opacity: 0.5;
                                        right: 0.1rem;
                                    }
                                }
                            }
                        }

                        .form-control {
                            &.modal-form {
                                padding: 2rem 0;

                                .activity-wrapper {
                                    align-items: center;
                                    display: flex;
                                    justify-content: flex-end;

                                    .secondary-title {
                                        margin-right: 1rem;
                                    }
                                }

                                // .pricing-strategy-calendar {
                                //     width: 100%;

                                //     .react-datepicker__current-month {
                                //         font-family: Montserrat, sans-serif;
                                //         font-size: 1.6rem;
                                //         font-weight: 500;
                                //     }

                                //     .react-datepicker__month {
                                //         border-collapse: collapse;
                                //         margin: 0;

                                //         .react-datepicker__day {
                                //             border: thin solid $lightest-text-color;
                                //             display: flex;
                                //             flex: 1;
                                //             font-family: Montserrat, sans-serif;
                                //             font-style: normal;
                                //             margin: 0;
                                //             min-height: 5rem;

                                //             &--outside-month {
                                //                 opacity: 0.5;

                                //                 .day-wrapper {
                                //                     color: $lighter-text-color;
                                //                 }
                                //             }

                                //             &--in-range {
                                //                 background-color: $day-in-selection-color
                                //             }

                                //             &.react-datepicker__day--range-end, &.react-datepicker__day--range-start, &.react-datepicker__day--selected, &.react-datepicker__day--keyboard-selected {
                                //                 background-color: $primary-color;

                                //                 .day-wrapper {
                                //                     color: $white-color;

                                //                     .day-item, .price-item {
                                //                         color: $white-color;
                                //                     }
                                //                 }
                                //             }

                                //             .day-wrapper {
                                //                 display: flex;
                                //                 flex: 1;
                                //                 font-weight: 600;
                                //                 height: 100%;
                                //                 position: relative;

                                //                 .day-item {
                                //                     color: $lighter-text-color;
                                //                     font-size: $base-font-size;
                                //                     left: 0.5rem;
                                //                     letter-spacing: 0.1rem;
                                //                     position: absolute;
                                //                     top: 0.5rem;
                                //                 }

                                //                 .price-item {
                                //                     bottom: 1rem;
                                //                     color: $primary-color;
                                //                     font-size: $xxxm-font-size;
                                //                     position: absolute;
                                //                     right: 2rem;

                                //                     &.disactive {
                                //                         color: $disabled-day-color;
                                //                         text-decoration: line-through;
                                //                     }
                                //                 }
                                //             }
                                //         }
                                //     }
                                // }

                                .basic-single {
                                    padding: 0;

                                    .select__control {
                                        border-radius: 0.6rem;
                                    }
                                }

                                .yacht-type-input {
                                    .basic-multi-select {
                                        .select__control {
                                            height: unset;
                                            min-height: 4.2rem;
                                        }
                                    }
                                }

                                .basic-multi-select {
                                    .select__menu {
                                        z-index: 1001;
                                    }

                                    .select__control {
                                        border-radius: 0.6rem;
                                        min-height: unset;

                                        .select__value-container {
                                            padding: 0.4rem 1.8rem;
                                        }
                                    }
                                }

                                .label-container {
                                    label {
                                        color: $basic-text-color;
                                        display: flex;
                                        font-size: $base-font-size;
                                        font-weight: 500;

                                        &.label-hidden {
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &.create-berth {
                        padding: 4rem;

                        .form-control.modal-form {
                            padding: 1rem 0;

                            .basic-single  {
                                padding: 0;

                                .select__menu {
                                    z-index: 1001;
                                }
                            }

                            .location-input-wrapper {
                                margin-top: 2rem;
                            }
                        }
                    }

                    .create-marina {
                        .default-form {
                            &.create-marina &.create-pricing-strategy {
                                .form-control-wrapper {
                                    .basic-single {
                                        padding: 0;

                                        .select__control {
                                            border-radius: 0.6rem;
                                        }
                                    }

                                    .basic-multi-select {
                                        .select__menu {
                                            z-index: 1001;
                                        }

                                        .select__control {
                                            border-radius: 0.6rem;
                                            min-height: unset;

                                            .select__value-container {
                                                padding: 0.4rem 1.8rem;
                                            }
                                        }
                                    }

                                    .label-container {
                                        label {
                                            color: $basic-text-color;
                                            display: flex;
                                            font-size: $base-font-size;
                                            font-weight: 500;

                                            &.label-hidden {
                                                display: none;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                }
            }
        }
    }

}
