@import "../../../../../../assets/styles/variables.scss";

.tagItem {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 57rem;
    padding-bottom: 3rem;
    padding-top: 3rem;

    .tagInfo {
        align-items: center;
        display: flex;
        margin-right: 5rem;

        .tagIcon {
            background: $sidebar-menu-hover-color;
            border-radius: 50%;
            height: 4.4rem;
            margin-right: 2rem;
            padding: 1rem;
            width: 4.4rem;
        }

        .tagName {
            color: $sidebar-menu-item;
            font-size: 1.6rem;
            font-weight: 500;
        }

        .tagDescription {
            font-size: 1.4rem;
        }
    }

    .btns {
        align-items: center;
        display: flex;
        justify-content: space-between;
    }
}

h2 {
    font-size: $base-m-font-size;
    font-weight: 600;
    margin-bottom: 2rem;
}
