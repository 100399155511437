.container{
    padding: 4rem;

    ul{
        display: flex;
        flex-wrap: wrap-reverse;
        margin-top: 1rem;
        padding: 0;

        li{
            list-style: none;
            list-style-type: none;
            margin-right: 2rem;
            position: relative;

            .galleryPhoto{
                height:12.5rem;
                margin-bottom: 1rem;
                object-fit: cover;
                width: 15rem;
            }

            .deleteBtn{
                background: rgba(255,255,255,0.2);
                border-radius: 0.5rem;
                cursor: pointer;
                padding: 0.5rem;
                position: absolute;
                right: 1rem;
                top:1rem;

                &:hover{
                    color: red;
                }
            }
        }
    }

    .uploader{
        position: relative;

        div{
            div{
                div{
                    img{
                        height:12rem ;
                        width: 19rem;
                    }
                }
            }
        }  

        input{
            padding-top: 1rem;
        }

        input+button{
            display: none;
        }

        .confirmBtns{
            position: absolute;
            right: -15rem;
            top: 0.1rem;

            button{
                margin-right: 1rem;
            }
        }
    }
}

.headerContainer{
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 4rem 4rem 0 3.5rem;

    .title{
        border-bottom: 1px solid rgba(0,0,0,0.05);
        font-size: 2.2rem;
        font-weight: 500;
    }
}


.btn{
    background: none;
    border:1px solid;
    border-radius: 0.5rem;
    font-size: 1.1rem;
    font-weight: 500;
    line-height: 1.34rem;
    margin-left: 2rem;
    max-height: 3rem;
    min-width: 7.5rem;
    padding: 0.6rem;
}

.cancelBtn{
    background: none;
    font-size: 1.1rem;
    font-weight: 500;
    line-height: 1.34rem;
    text-decoration: underline;
}

.closeBtn {
    background: none;
    height: 1.5rem;
    position: relative;
    width: 1.5rem;

    &::after{
        border-bottom: 0.2rem solid;
        content: '';
        left: 0;
        position: absolute;
        transform: rotate(45deg);
        width: 1.5rem;
    }

    &::before{
        border-bottom: 0.2rem solid;
        content: '';
        left: 0;
        position: absolute;
        transform: rotate(-45deg);
        width: 1.5rem;
    }
}